import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Steps,
  message,
  notification,
  Alert,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useUserStore from "../context/userInformatioContext";
import type { NotificationArgsProps } from "antd";

const { Option } = Select;
const { Step } = Steps;
type NotificationPlacement = NotificationArgsProps["placement"];

const CreateAccount: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const { updateUser, updateToken } = useUserStore();
  const [errorMessage, setErrorMessage] = useState("");
  const [current, setCurrent] = useState(0);

  const [basicUserInfo, setBasicUserInfo] = useState({
    email: "",
    password: "",
  });
  const [extendedUserInfo, setExtendedUserInfo] = useState<any>({
    userName: "",

    currentAge: 0,
    retireAge: 0,
    country: "",
    currentSavings: 0,
    currentDebt: 0,
    incomeAfterTax: 0,
    savingsPerMonth: 0,
    debtRepaymentPerMonth: 0,
    //names
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (
    placement: NotificationPlacement,
    errorMessage: string
  ) => {
    api.error({
      message: `Error creating account`,
      description: errorMessage,
      placement,
    });
  };

  const onFinish = async (values: any) => {
    try {
      await axios
        .post(`${baseUrl}user/register`, basicUserInfo)
        .then(async (res) => {
          await axios
            .post(`${baseUrl}auth/login`, basicUserInfo)
            .then(async (res) => {
              console.log("logged!", res);
              await updateToken(res.data.token);
              await axios
                .patch(
                  `${baseUrl}api/extended-info`,
                  {
                    ...extendedUserInfo,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${res.data.token}`,
                    },
                  }
                )
                .then((res) => {
                  updateUser(res.data.user);
                  navigate("/survey");
                });
            });
        })
        .catch(async (err) => {
          await openNotification("top", err?.response?.data?.message);
        });
    } catch (error) {
      console.log("Registration failed:", error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Account",
      content: (
        <>
          <Form.Item
            label="What’s your name?"
            name="userName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
                type: "string",
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  userName: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="What’s your email address?"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email address!",
                type: "email",
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setBasicUserInfo({ ...basicUserInfo, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Create a password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              onChange={(e) =>
                setBasicUserInfo({ ...basicUserInfo, password: e.target.value })
              }
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Age",
      content: (
        <>
          <Form.Item
            label="How old are you?"
            name="currentAge"
            rules={[{ required: true, message: "Please input your age!" }]}
          >
            <Input
              type="number"
              onChange={(e) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  currentAge: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="What age do you want to retire?"
            name="retireAge"
            rules={[
              {
                required: true,
                message: "Please input your desired retirement age!",
              },
            ]}
          >
            <Input
              type="number"
              onChange={(e) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  retireAge: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Location & Finances",
      content: (
        <>
          <Form.Item
            label="What country do you live in?"
            name="country"
            rules={[{ required: true, message: "Please select your country!" }]}
          >
            <Select
              style={{
                height: "50px",
                fontSize: "18px",
              }}
              onChange={(value) =>
                setExtendedUserInfo({ ...extendedUserInfo, country: value })
              }
            >
              <Option value="usa">USA</Option>
              <Option value="canada">Canada</Option>
              <Option value="uk">UK</Option>
              {/* Add more options as needed */}
            </Select>
          </Form.Item>
          <Form.Item
            label="How much money do you have saved up? "
            name="currentSavings"
            rules={[{ required: true, message: "Please input your savings!" }]}
            extra="(Don’t include the value of your house if you own one) "
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/(,*)/g, "")}
              onChange={(value) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  currentSavings: value || 0,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Do you have any debt? "
            name="currentDebt"
            rules={[
              { required: true, message: "Please input your debt amount!" },
            ]}
            extra="(Include all forms of debt, including your mortgage)"
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/(,*)/g, "")}
              onChange={(value) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  currentDebt: value || 0,
                })
              }
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Income & Expenses",
      content: (
        <>
          <Form.Item
            label="What’s your annual income after tax? "
            name="incomeAfterTax"
            rules={[
              { required: true, message: "Please input your annual income!" },
            ]}
            extra="(e.g. if you get paid every other week, multiply your paycheck by 26)."
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/(,*)/g, "")}
              onChange={(value) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  incomeAfterTax: value || 0,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="On average, how much do you save every month? "
            name="savingsPerMonth"
            rules={[
              { required: true, message: "Please input your monthly savings!" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/(,*)/g, "")}
              onChange={(value) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  savingsPerMonth: value || 0,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="How much do you spend on debt repayment each month? "
            name="debtRepaymentPerMonth"
            rules={[
              {
                required: true,
                message: "Please input your monthly debt repayment!",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/(,*)/g, "")}
              onChange={(value) =>
                setExtendedUserInfo({
                  ...extendedUserInfo,
                  debtRepaymentPerMonth: value || 0,
                })
              }
            />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {contextHolder}

      <p
        style={{
          fontSize: "96px",
          fontWeight: 600,
          margin: "0 1rem",
          color: "#C0CFB2",
          position: "absolute",
          top: "27px",
          left: "63px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        sage
      </p>
      <Form
        name="createAccount"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 800,
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        layout="vertical"
      >
        <Steps
          current={current}
          style={{
            marginBottom: 24,
          }}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action" style={{ marginTop: 24 }}>
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}

          {errorMessage !== "" && (
            <Alert
              message={errorMessage}
              type="error"
              showIcon
              style={{ marginBottom: "1rem" }}
            />
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              disabled={
                (current === 0 &&
                  (!basicUserInfo.email ||
                    !extendedUserInfo.userName ||
                    !basicUserInfo.password)) ||
                (current === 1 &&
                  (!extendedUserInfo.currentAge ||
                    !extendedUserInfo.retireAge)) ||
                (current === 2 &&
                  (!extendedUserInfo.country ||
                    !extendedUserInfo.currentSavings))
              }
              onClick={() => next()}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CreateAccount;
